import type { Banner2CarouselComponent } from 'server/repository/hygraph/generated/graphqlTypes';
import type { ISharedTemplateProps } from 'templates/TemplateMapper/types';
import { Banner2, Banner2TemplateProps } from '../Banner2';
import { CarouselCard } from './styles';
import { Slider } from '@/components/SliderVanilla';

export interface IBanner2Slider extends ISharedTemplateProps {
  templateProps: Banner2CarouselComponent;
}

export const Banner2Slider = ({ templateProps }: IBanner2Slider) => {
  return (
    <Slider slidesPerView={1} slidesPerGroup={1} isFullWidth={true} data-test="slider">
      {templateProps.banner2.map((banner, index: number) => {
        const templateProps = banner as Banner2TemplateProps;

        return (
          <CarouselCard key={banner.id ?? `slider-banners-${index}`}>
            <Banner2 templateProps={templateProps} />
          </CarouselCard>
        );
      })}
    </Slider>
  );
};
